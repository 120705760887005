import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import { emotionTransform, MantineEmotionProvider } from "@mantine/emotion";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import queryClient from "~/queryClient";
import theme from "../theme";

export default function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={theme} stylesTransform={emotionTransform}>
          <MantineEmotionProvider>
            <Outlet />
          </MantineEmotionProvider>
        </MantineProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
