import type { MantineThemeOverride } from "@mantine/core";

const theme = {
  fontFamily:
    '"Source Sans Pro", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  headings: {
    fontFamily:
      '"Open Sans", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontWeight: "300",
    sizes: {
      h1: { fontSize: "2.625rem", lineHeight: `${52 / 42}` },
      h2: { fontSize: "2rem", lineHeight: `${42 / 32}` },
      h3: { fontSize: "1.5rem", lineHeight: `${36 / 24}` },
      h4: { fontSize: "1.25rem", lineHeight: `${24 / 20}` },
      // h6: { fontSize: "4.25rem", lineHeight: `${82 / 68}` }, // PageTitle style
    },
  },
  spacing: {
    xs: "0.25rem",
    sm: "0.5rem",
    md: "1rem",
    lg: "2rem",
    xl: "4rem",
  },
  components: {
    Group: { defaultProps: { align: "baseline" } },
    Stack: { defaultProps: { spacing: "lg" } },
    InputWrapper: {
      defaultProps: {
        labelProps: { size: "sm", lts: 0.2, d: "block", c: "gray.8" },
        descriptionProps: { mb: "sm" },
      },
    },
    Input: {
      styles: { wrapper: { marginBlock: 4 } },
      defaultProps: {
        variant: "filled",
        size: "sm",
      },
    },
  },
} as const satisfies MantineThemeOverride;

export default theme;
